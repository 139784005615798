<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center flex-wrap justify-center">
        <div>
          {{ size }}
        </div>
        <v-btn icon @click="rotate(90)">
          <v-icon>$rotate</v-icon>
        </v-btn>
        <v-btn icon @click="zoom(0.1)">
          <v-icon>$zoom_plus</v-icon>
        </v-btn>
        <v-btn icon @click="zoom(-0.1)">
          <v-icon>$zoom_minus</v-icon>
        </v-btn>
        <v-btn icon @click="flipHorizontal">
          <v-icon>$flip-horizontal</v-icon>
        </v-btn>
        <v-btn icon @click="flipVertical">
          <v-icon>$flip-vertical</v-icon>
        </v-btn>
        <v-btn icon @click="reset">
          <v-icon>$reset</v-icon>
        </v-btn>
        <v-btn color="primary" :disabled="isLoading" class="my-2" @click="save">
          {{ $trans("save") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-2 mb-8" />
    <v-row justify="center">
      <v-col cols="12" class="d-flex justify-center align-center fill-height">
        <img
          id="cropper-image"
          class="image"
          :src="$config('image_base_url') + getImageEdit.default_image.name"
        />
      </v-col>
    </v-row>
    <v-overlay absolute color="white" :value="isLoading" />
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import Cropper from "cropperjs";
import api from "@/lib/calendesk-js-library/api/api";
import sharedDialogTypes from "@/lib/calendesk-js-library/components/dialogs/sharedDialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "EditorImageDialog",
  mixins: [sharedActions],
  data() {
    return {
      editor: null,
      imageUrl: null,
      isLoading: false,
      canvas: null,
      size: null,
    };
  },
  computed: {
    ...mapGetters({
      getImageEdit: "assets/getImageEdit",
    }),
  },
  created() {
    this.isLoading = true;
    this.$emit("loading", this.isLoading);
    setTimeout(() => {
      this.initEditor();
    }, 1000);
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    ...mapMutations({
      setReload: "assets/SET_RELOAD",
    }),
    initEditor() {
      const image = document.getElementById("cropper-image");
      this.editor = new Cropper(image, {
        background: false,
        ready: this.editorReady,
        crop: this.crop,
      });
    },
    editorReady() {
      this.isLoading = false;
      this.$emit("loading", this.isLoading);
    },
    crop(data) {
      if (data.detail && data.detail.width && data.detail.height) {
        this.size = `${parseInt(data.detail.width)}x${parseInt(
          data.detail.height
        )} px`;
      }
    },
    reset() {
      this.editor.reset();
    },
    zoom(scale) {
      this.editor.zoom(scale);
    },
    rotate(angle) {
      this.editor.rotate(angle);
    },
    save() {
      this.canvas = this.editor.getCroppedCanvas({
        maxWidth: 4096,
        maxHeight: 4096,
      });

      this.canvas.toBlob((blob) => this.upload(blob));
    },
    flipHorizontal() {
      const { scaleX } = this.editor.getImageData();

      if (scaleX === -1) {
        this.editor.scaleX(1);
      } else {
        this.editor.scaleX(-1);
      }
    },
    flipVertical() {
      const { scaleY } = this.editor.getImageData();

      if (scaleY === -1) {
        this.editor.scaleY(1);
      } else {
        this.editor.scaleY(-1);
      }
    },
    upload(blob) {
      this.isLoading = true;
      this.$emit("loading", this.isLoading);
      const body = new FormData();
      body.append("image", blob);
      body.append("name", this.$moment().format(this.$helpers.dateTimeFormat));
      const uploadImage = api.uploadUserImage(body);
      Promise.all([uploadImage])
        .then(() => {})
        .catch((error) => this.$log.error(error))
        .finally(() => {
          this.setReload();
          this.openDialog({
            type: sharedDialogTypes.ASSETS_DIALOG,
            title: this.$trans("files"),
            size: dialogSize.FULL_SCREEN,
          });
          this.isLoading = false;
          this.$emit("loading", this.isLoading);
        });
    },
  },
};
</script>

<style>
@import "~cropperjs/dist/cropper.min.css";
</style>

<style lang="scss" scoped>
.image {
  max-width: 100%;
  max-height: 800px;
}
</style>
